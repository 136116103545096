<template>
  <div class="grid crud-demo">
    <div class="col-12">
      <div class="card">
        <div class="containerClass">
          <OrganizationTopBar />
        </div>
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Crear reserva"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
            </div>
          </template>
        </Toolbar>

        <DataTable
          sortField="bookingDate"
          :sortOrder="1"
          ref="dt"
          :loading="loading"
          :totalRecords="totalElements"
          :value="bookings"
          :lazy="true"
          v-model:selection="selectedBookings"
          v-model:expandedRows="expandedRows"
          dataKey="id"
          :paginator="true"
          :rows="10"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 20, 40]"
          currentPageReportTemplate="Mostrando de la {first}ª a la {last}ª de {totalRecords} reservas"
          responsiveLayout="scroll"
          @page="onFetch($event)"
          @sort="onFetch($event)"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0">Gestionar reservas</h5>
              <div class="flex align-items-center" style="gap: 1em">
                <Button
                  @click="refreshBookings"
                  icon="pi pi-refresh"
                  class="p-button-outlined"
                  aria-label="Refrescar reservas"
                />
                <div class="field-checkbox mb-0">
                  <Checkbox
                    id="showHistory"
                    v-model="showHistory"
                    :binary="true"
                  />
                  <label for="showHistory">Mostrar reservas pasadas</label>
                </div>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="query"
                    @input="onTextFilterChange"
                    placeholder="Buscar..."
                  />
                </span>
              </div>
            </div>
          </template>

          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="bookingCode" header="Código" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              <span v-if="slotProps.data.blacklisted" style="color:red" v-tooltip="'Cliente bloqueado'">
                <i class="pi pi-exclamation-triangle mr-3" />
              </span>
              {{ slotProps.data.bookingCode }}
            </template>
          </Column>
          <Column
            field="createdDate"
            header="Fecha de creación"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Fecha de creación</span>
              {{ formatDateWithoutTimeZone(slotProps.data.createdDate) }}
            </template>
          </Column>
          <Column field="bookingDate" header="Fecha" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Date</span>
              {{ formatDateWithoutTimeZone(slotProps.data.bookingDate) }}
            </template>
          </Column>
          <Column field="state" header="Estado" :sortable="true">
            <template #body="slotProps">
              <chip :class="`${getChipClass(slotProps.data.state)} chip`">
                {{ getStateLabel(slotProps.data.state) }}
              </chip>
            </template>
          </Column>
          <Column field="hasBeenCharged" header="Recargo cobrado" :sortable="true" :nullSortOrder="0">
            <template #body="slotProps">
              <span>{{ slotProps.data.hasBeenCharged === true ? 'Sí' : 'No' }}</span>
            </template>
          </Column>
          <Column
            field="customFields.firstname"
            header="Nombre"
            :sortable="true"
          ></Column>
          <Column
            field="customFields.lastname"
            header="Apellido"
            :sortable="true"
          >
          </Column>
          <Column field="customFields.phone" header="Teléfono" :sortable="true">
          </Column>
          <Column field="source" header="Origen" :sortable="true"> </Column>
          <Column>
            <template #body="slotProps">
              <div class="flex" style="gap: 0.7em">
                <template v-if="slotProps.data.state == 'PENDING'">
                  <Button
                    icon="pi pi-check-circle"
                    class="p-button-rounded p-button-success"
                    @click="acceptBooking(slotProps.data)"
                  />
                  <Button
                    icon="pi pi-minus-circle"
                    class="p-button-rounded rejected"
                    @click="rejectBooking(slotProps.data)"
                  />
                </template>
                <template v-if="slotProps.data.state == 'ACCEPTED'">
                  <Button
                    icon="pi pi-ban"
                    class="p-button-rounded cancelled"
                    @click="cancelBooking(slotProps.data)"
                  />
                </template>
                <Button
                  :icon="
                    slotProps.data.blacklisted
                      ? 'pi pi-user-plus'
                      : 'pi pi-user-minus'
                  "
                  class="p-button-rounded p-button-warning"
                  :aria-label="
                    slotProps.data.blacklisted
                      ? 'Quitar de la lista negra'
                      : 'Añadir a la lista negra'
                  "
                  @click="
                    showBlacklistDialog({
                      id: slotProps.data.customerId,
                      email: slotProps.data.customFields.email,
                      blacklisted: !slotProps.data.blacklisted,
                    })
                  "
                />
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded"
                  @click="editBooking(slotProps.data)"
                />
                <Button
                    icon="pi pi-euro"
                    class="p-button-rounded charge-booking"
                    @click="chargeBooking(slotProps.data)"
                    :disabled="loadingChargeBooking"
                    v-if="cancellationPolicyEnabled && slotProps.data.setupPaymentId !== null && slotProps.data.hasBeenCharged === false"
                  />
              </div>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div v-for="customField in customFields" :key="customField.name">
              {{ customField.name }}:
              {{ slotProps.data.customFields[customField.name] }}
            </div>
          </template>
        </DataTable>

        <Dialog
          :visible="bookingDialog || editDialog"
          @update:visible="hideDialog"
          :style="{ width: '450px' }"
          header="Detalles de la reserva"
          :modal="true"
          class="p-fluid"
        >
          <h5>Cliente</h5>
          <div class="field">
            <label for="phone">Teléfono del cliente</label>
            <div style="position: relative">
              <vue-tel-input
                defaultCountry="es"
                v-model="phone"
                @validate="phoneValidationChange"
                @country-changed="phoneCountryChange"
                @focus="() => showCustomersPopup(true)"
                @blur="() => showCustomersPopup(false)"
                :inputOptions="{ autocomplete: 'off' }"
                :class="{
                  'p-invalid': submitted && (!phone || phoneFormatError),
                }"
              ></vue-tel-input>
              <CustomerPopup
                :organizationId="organizationId"
                :phone="phone?.replaceAll(' ', '') ?? ''"
                :dialCode="booking.customFields.dialCode ?? ''"
                :show="showCustomers"
                @onChange="onCustomerSelect"
              />
            </div>
            <small class="p-invalid" v-if="submitted && !phone"
              >El teléfono es obligatorio.</small
            >
            <small class="p-invalid" v-if="phoneFormatError"
              >Formato de teléfono no válido</small
            >
          </div>
          <div class="field">
            <label for="fname"
              >Nombre del cliente<span class="error-text">*</span></label
            >
            <InputText
              id="fname"
              v-model.trim="booking.customFields.firstname"
              required="true"
              :class="{
                'p-invalid': submitted && !booking.customFields.firstname,
              }"
            />
            <small
              class="error-text"
              v-if="submitted && !booking.customFields.firstname"
              >El nombre es obligatorio.</small
            >
          </div>
          <div class="field">
            <label for="lname">Apellido del cliente</label>
            <InputText
              id="lname"
              v-model.trim="booking.customFields.lastname"
              required="true"
            />
            <small
              class="p-invalid"
              v-if="submitted && !booking.customFields.lastname"
              >El apellido es obligatorio.</small
            >
          </div>
          <div class="field">
            <label for="email">Email del cliente</label>
            <InputText
              id="email"
              v-model.trim="booking.customFields.email"
              :class="{
                'p-invalid': emailFormatError,
              }"
            />
            <small class="error-text" v-if="emailFormatError"
              >Formato de email no válido</small
            >
          </div>

          <h5>Reserva</h5>
          <div class="field">
            <label for="bookingDate"
              >Fecha de la reserva<span class="error-text">*</span></label
            >
            <Calendar
              :showTime="true"
              id="bookingDate"
              autocomplete="off"
              :minDate="editDialog ? null : getMinBookingDate()"
              :showIcon="true"
              :showButtonBar="true"
              v-model="booking.bookingDate"
              :class="{ 'p-invalid': submitted && !booking.bookingdate }"
            ></Calendar>
            <small class="error-text" v-if="submitted && !booking.bookingDate"
              >La fecha de la reserva es obligatoria.</small
            >
          </div>

          <h5 v-if="customFields && !!customFields.length">
            Campos personalizados
          </h5>

          <div
            class="field"
            v-for="customField in customFields"
            :key="customField.name"
          >
            <label :for="customField.name">{{ customField.label }}</label>
            <InputText
              :id="customField.name"
              v-model.trim="booking.customFields[customField.name]"
              v-if="customField['@type'] === CUSTOM_FORM_FIELD_TYPES.TEXT"
            />
            <Calendar
              :id="customField.name"
              autocomplete="off"
              :showIcon="true"
              :showButtonBar="true"
              v-model="booking.customFields[customField.name]"
              v-else-if="
                customField['@type'] === CUSTOM_FORM_FIELD_TYPES.CALENDAR
              "
            ></Calendar>
            <Textarea
              :id="customField.name"
              v-model.trim="booking.customFields[customField.name]"
              v-else-if="
                customField['@type'] === CUSTOM_FORM_FIELD_TYPES.TEXTAREA
              "
            />
            <Dropdown
              :id="customField.name"
              v-model="booking.customFields[customField.name]"
              :options="customField.dropdownOptions"
              v-else-if="
                customField['@type'] === CUSTOM_FORM_FIELD_TYPES.DROPDOWN
              "
            />
          </div>
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-secondary"
              @click="hideDialog"
            />
            <Button
              label="Crear"
              icon="pi pi-check"
              @click="onCreateClick"
              v-if="bookingDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              @click="updateBooking"
              v-if="editDialog"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="isShowBlacklistDialog"
          :style="{ width: '450px' }"
          header="Actualizar lista negra"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span class="text-center" v-if="booking"
              >¿Estás seguro de que quieres
              {{ blacklistCustomer.blacklisted ? 'añadir a' : 'quitar de' }} la
              lista negra a {{ blacklistCustomer.email }}?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-secondary"
              @click="dismissBlacklistUpdate"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              @click="acceptBlacklistUpdate"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="rejectBookingDialog"
          :style="{ width: '450px' }"
          header="Rechazar reserva"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span class="text-center" v-if="booking"
              >¿Estás seguro de que quieres rechazar la reserva
              <b>{{ booking.bookingCode }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-secondary"
              @click="cancelRejectBooking"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              @click="confirmRejectBooking"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="acceptBookingDialog"
          :style="{ width: '450px' }"
          header="Aceptar reserva"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span class="text-center" v-if="booking"
              >¿Estás seguro de que quieres aceptar la reserva
              <b>{{ booking.bookingCode }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-secondary"
              @click="cancelAcceptBooking"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              @click="confirmAcceptBooking"
            />
          </template>
        </Dialog>
        <Dialog
          v-model:visible="cancelBookingDialog"
          :style="{ width: '450px' }"
          header="Cancelar reserva"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span class="text-center" v-if="booking"
              >¿Estás seguro de que quieres cancelar la reserva
              <b>{{ booking.bookingCode }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-secondary"
              @click="cancelCancelBooking"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              @click="confirmCancelBooking"
            />
          </template>
        </Dialog>
        <Dialog
          :visible="confirmBlacklistedDialog"
          @update:visible="hideDialog"
          :style="{ width: '450px' }"
          header="Cliente bloqueado"
          :modal="true"
          class="p-fluid"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span class="text-center" v-if="booking"
              >¿Estás seguro de que quieres crear la reserva? El cliente está
              bloqueado y no puede crear reservas por sí mismo</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-secondary"
              @click="closeBlacklistedBookingDialog"
            />
            <Button label="Sí" icon="pi pi-check" @click="saveBooking" />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="chargeBookingDialog"
          :style="{ width: '450px' }"
          header="Cobrar Recargo"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span class="text-center" v-if="booking"
              >¿Estás seguro de que quieres cobrar el recargo<span v-if="booking.bookingCharge !== null"> de {{new Intl.NumberFormat("es-ES", {minimumFractionDigits: 2,maximumFractionDigits: 2}).format(booking.bookingCharge)}}€</span> al cliente de la reserva
              <b>{{ booking.bookingCode }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-secondary"
              @click="cancelChargeBooking"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              @click="confirmChargeBooking"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import BookingService from '../service/BookingService';
import OrganizationService from '../service/OrganizationService';
import * as _ from 'lodash';
import {
  BOOKING_LIST_KEYS,
  CUSTOM_FORM_FIELD_TYPES,
  BOOKING_STATE_LABELS,
  BOOKING_STATE_CHIPS,
} from '../constants/constants';
import {
  formatDateForApi,
  formatApiDateTimeForHuman,
  formatApiDateTimeForHumanWithoutTimeZone,
  addMinutes,
} from '../utils/dates';
import OrganizationTopBar from './OrganizationTopbar.vue';
import { validateEmail } from '../utils/validation';

export default {
  data() {
    return {
      organizationId: this.$route.params.id,
      CUSTOM_FORM_FIELD_TYPES,
      loading: true,
      bookings: null,
      customFields: null,
      bookingDialog: false,
      confirmBlacklistedDialog: false,
      acceptBookingDialog: false,
      rejectBookingDialog: false,
      cancelBookingDialog: false,
      chargeBookingDialog: false,
      loadingChargeBooking: false,
      editDialog: false,
      booking: {},
      query: '',
      showHistory: false,
      selectedBookings: null,
      filters: {},
      bookingParams: {},
      totalElements: 0,
      submitted: false,
      emailFormatError: false,
      phoneFormatError: false,
      expandedRows: [],
      phone: '',
      showCustomers: false,
      isShowBlacklistDialog: false,
      blacklistCustomer: null,
      cancellationPolicyEnabled: null,
    };
  },
  bookingService: null,
  created() {
    this.bookingService = new BookingService(this.$route.params.id);
    this.organizationService = new OrganizationService();
  },
  mounted() {
    this.bookingParams = {
      page: 0,
      rows: this.$refs.dt.rows,
      sortField: 'bookingDate',
      sortOrder: 1,
    };
    this.loading = true;
    this.organizationService.getConfig(this.$route.params.id).then((config) => {
      this.customFields = config.bookingConfig.bookingFormConfig.formFields;
      this.cancellationPolicyEnabled = config.cancellationPolicy.enableCancellationPolicy;
    });
    this.fetchBookings();
  },
  watch: {
    'booking.customFields.email'(newEmail) {
      let email = '';
      newEmail ? (email = newEmail.trim()) : '';
      if (email.length === 0) {
        this.emailFormatError = false;
        return;
      }
      this.booking.customFields.email = email;
      this.emailFormatError = !validateEmail(email);
    },
    showHistory() {
      this.loading = true;
      this.fetchBookings();
    },
  },
  methods: {
    updateBlacklist(customerId, blacklisted, name) {
      this.loading = true;
      this.organizationService.updateCustomerBlacklist(
        this.organizationId,
        customerId,
        blacklisted
      );
      this.$toast.add({
        severity: 'success',
        summary: 'Lista negra actualizada',
        detail: `El usuario ${name} ha sido ${
          blacklisted ? 'añadido a' : 'eliminado de'
        } la lista negra`,
        life: 3000,
      });
      this.fetchBookings();
    },
    onCustomerSelect(customer) {
      this.phone = customer.phone + '';
      this.booking.customFields.firstname = customer.firstname;
      this.booking.customFields.lastname = customer.lastname;
      this.booking.customFields.email = customer.email;
      this.booking.blacklisted = customer.blacklisted;
    },
    showCustomersPopup(show) {
      setTimeout(() => (this.showCustomers = show), 150);
    },
    phoneCountryChange({ dialCode }) {
      this.booking.customFields.dialCode = dialCode;
    },
    phoneValidationChange(event) {
      const isValid = event.valid;
      this.phoneFormatError = !isValid;
    },
    getStateLabel(state) {
      return BOOKING_STATE_LABELS[state];
    },
    getChipClass(state) {
      return BOOKING_STATE_CHIPS[state];
    },
    formatDate(stringDate) {
      return formatApiDateTimeForHuman(stringDate);
    },
    formatDateWithoutTimeZone(stringDate) {
      return formatApiDateTimeForHumanWithoutTimeZone(stringDate);
    },
    refreshBookings() {
      this.loading = true;
      this.fetchBookings();
    },
    onTextFilterChange() {
      this.loading = true;
      this.fetchBookings();
    },
    onFetch(event) {
      this.bookingParams = event;
      this.loading = true;
      this.fetchBookings();
    },
    fetchBookings: _.debounce(async function () {
      const paginatedReponse = await this.bookingService.getBookings(
        this.bookingParams.page,
        this.bookingParams.rows,
        this.showHistory,
        this.buildApiSort(
          this.bookingParams.sortField,
          this.bookingParams.sortOrder
        ),
        this.query
      );

      this.bookings = paginatedReponse.content;
      this.totalElements = paginatedReponse.totalElements;
      this.loading = false;
    }, 250),
    buildApiSort(sortBy, sortDirection) {
      if (sortBy === null || sortBy === undefined) return null;
      const direction = sortDirection > 0 ? 'ASC' : 'DESC';
      return {
        field: BOOKING_LIST_KEYS[sortBy],
        direction,
      };
    },
    openNew() {
      this.booking = {
        customFields: {},
      };
      this.submitted = false;
      this.bookingDialog = true;
    },
    hideDialog() {
      this.bookingDialog = false;
      this.editDialog = false;
      this.submitted = false;
    },
    validateBooking() {
      this.booking.customFields.phone = this.phone.replaceAll(' ', '');
      if (
        this.booking.customFields.firstname?.trim() &&
        this.booking.customFields.phone?.trim() &&
        this.booking.bookingDate &&
        this.booking.customFields.phone?.replaceAll(' ', '')
      ) {
        if (this.emailFormatError) {
          document.getElementById('email')?.focus?.();
          return false;
        }
        if (this.phoneFormatError) {
          document.getElementById('phone')?.focus?.();
          this.submitted = false;
          return false;
        }
        return true;
      }
      return false;
    },
    parseBooking() {
      const parsedBooking = this.booking;
      const calendarFieldsKeys = this.customFields
        .filter(
          (field) => field['@type'] === this.CUSTOM_FORM_FIELD_TYPES.CALENDAR
        )
        .map((field) => field.name);
      calendarFieldsKeys.forEach((fieldKey) => {
        parsedBooking.customFields[fieldKey] = parsedBooking?.customFields?.[
          fieldKey
        ]
          ? formatDateForApi(parsedBooking.customFields[fieldKey])
          : null;
      });
      return parsedBooking;
    },
    closeBlacklistedBookingDialog() {
      this.confirmBlacklistedDialog = false;
    },
    onCreateClick() {
      if (!this.validateBooking()) return;
      if (this.booking.blacklisted) this.confirmBlacklistedDialog = true;
      else this.saveBooking();
    },
    async saveBooking() {
      this.confirmBlacklistedDialog = false;
      this.submitted = true;
      this.loading = true;
      this.hideDialog();
      try {
        await this.bookingService.postBooking(this.parseBooking());
        this.booking = {
          customFields: {},
        };
        this.$toast.add({
          severity: 'success',
          summary: `Reserva creada con éxito`,
          life: 3000,
        });
      } catch (error) {
        console.log(error);
        console.table(error);
        const message =
          error.response.status === 409
            ? 'Ya existe una reserva para esa fecha y ese cliente'
            : `No hemos podido crear tu reserva, por favor inténtalo más tarde`;
        this.$toast.add({
          severity: 'error',
          summary: message,
          life: 3000,
        });
      }
      await this.fetchBookings();
      this.loading = false;
    },
    async updateBooking() {
      if (!this.validateBooking()) return;
      this.submitted = true;
      this.loading = true;
      this.hideDialog();
      try {
        await this.bookingService.editBooking(
          this.parseBooking(),
          this.booking.id
        );
        this.booking = {
          customFields: {},
        };
        this.$toast.add({
          severity: 'success',
          summary: `Reserva actualizada con éxito`,
          life: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: 'error',
          summary: `No hemos podido actualizar tu reserva, por favor inténtalo más tarde`,
          life: 3000,
        });
      }
      await this.fetchBookings();
      this.loading = false;
    },
    acceptBooking(selectedBooking) {
      this.acceptBookingDialog = true;
      this.booking = selectedBooking;
    },
    async chargeBooking(selectedBooking) {
      this.loadingChargeBooking = true;
      try {
        const response = await this.bookingService.retrieveBookingCharge(selectedBooking);
        selectedBooking.bookingCharge = response.charge
      } catch (err) {
        console.error("An error occured while retrieving the booking charge", err);
        selectedBooking.bookingCharge = null;
      }
      this.loadingChargeBooking = false;

      this.booking = selectedBooking;
      this.chargeBookingDialog = true;
    },
    async confirmAcceptBooking() {
      this.bookingService
        .acceptBooking(this.booking)
        .then((data) => {
          this.$toast.add({
            severity: 'success',
            summary: `Reserva ${data.bookingCode} aceptada`,
            life: 3000,
          });
          this.bookings = this.bookings.map((arrayBooking) =>
            arrayBooking.bookingCode === data.bookingCode ? data : arrayBooking
          );
        })
        .catch((data) => {
          console.log(data);
          if (data.response.status === 440) {
            this.$toast.add({
              severity: 'error',
              summary: `Ha habido un error aceptando la reserva ${data?.booking?.bookingCode}`,
              detail: 'Las reservas pasadas no pueden ser aceptadas',
              life: 3000,
            });
            // Update bookings
            this.loading = true;
            this.fetchBookings();
            return;
          }
          this.$toast.add({
            severity: 'error',
            summary: `Ha habido un error aceptando la reserva ${data?.booking?.bookingCode}`,
            detail: 'Inténtelo más tarde',
            life: 3000,
          });
        });
      this.booking = { customFields: {} };
      this.acceptBookingDialog = false;
    },
    cancelAcceptBooking() {
      this.booking = { customFields: {} };
      this.acceptBookingDialog = false;
    },
    rejectBooking(selectedBooking) {
      this.booking = selectedBooking;
      this.rejectBookingDialog = true;
    },
    async confirmChargeBooking() {
      console.log(this.booking);
      const bookingCode = this.booking.bookingCode;
      this.loadingChargeBooking = true;
      this.bookingService
        .chargeBooking(this.booking)
        .then((data) => {
          console.log(data);
          this.$toast.add({
            severity: 'success',
            summary: `Recargo de ${bookingCode} cobrado.`,
            detail: `El recargo de la reserva ${bookingCode} ha sido cobrado satisfactoriamente.`,
            life: 3000,
          });
          this.bookings = this.bookings.map((arrayBooking) =>
            arrayBooking.bookingCode === data.bookingCode ? data : arrayBooking);
          this.loadingChargeBooking = false;
        })
        .catch((data) => {
          console.log(data);
          this.$toast.add({
            severity: 'error',
            summary: `Ha ocurrido un error al cobrar el recargo. `,
            detail: `Ha ocurrido un error al cobrar el recargo de la reserva ${bookingCode}. Por favor, inténtelo más tarde.`,
            life: 3000,
          });
          this.loadingChargeBooking = false;
        });
      this.booking = { customFields: {} };
      this.chargeBookingDialog = false;
    },
    cancelChargeBooking() {
      this.booking = { customFields: {} };
      this.chargeBookingDialog = false;
    },
    editBooking(selectedBooking) {
      this.booking = _.cloneDeep(selectedBooking);
      this.booking.bookingDate = DateTime.fromISO(
        this.booking.bookingDate
      ).toJSDate();
      this.phone = `${selectedBooking.customFields.dialCode}${selectedBooking.customFields.phone}`;
      this.editDialog = true;
    },
    cancelBooking(selectedBooking) {
      this.booking = selectedBooking;
      this.cancelBookingDialog = true;
    },
    cancelCancelBooking() {
      this.cancelBookingDialog = false;
      this.booking = { customFields: {} };
    },
    async confirmCancelBooking() {
      this.bookingService
        .cancelBooking(this.booking)
        .then((data) => {
          this.$toast.add({
            severity: 'success',
            summary: `Reserva ${data.bookingCode} cancelada`,
            life: 3000,
          });
          this.bookings = this.bookings.map((arrayBooking) =>
            arrayBooking.bookingCode === data.bookingCode ? data : arrayBooking
          );
        })
        .catch((data) => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error cancelando la reserva',
            detail: `Hubo un error cancelando la reserva ${data.booking.bookingCode}, por favor, inténtelo mas tarde`,
            life: 3000,
          });
        });
      this.booking = { customFields: {} };
      this.cancelBookingDialog = false;
    },
    async confirmRejectBooking() {
      this.bookingService
        .rejectBooking(this.booking)
        .then((data) => {
          this.$toast.add({
            severity: 'success',
            summary: `Reserva ${data.bookingCode} rechazada`,
            life: 3000,
          });
          this.bookings = this.bookings.map((arrayBooking) =>
            arrayBooking.bookingCode === data.bookingCode ? data : arrayBooking
          );
        })
        .catch((data) => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error rechazando la reserva',
            detail: `Hubo un error rechazando la reserva ${data.booking.bookingCode}, por favor, inténtelo mas tarde`,
            life: 3000,
          });
        });
      this.booking = { customFields: {} };
      this.rejectBookingDialog = false;
    },
    cancelRejectBooking() {
      this.booking = { customFields: {} };
      this.rejectBookingDialog = false;
    },
    acceptBlacklistUpdate() {
      this.loading = true;
      this.isShowBlacklistDialog = false;
      this.updateBlacklist(
        this.blacklistCustomer.id,
        this.blacklistCustomer.blacklisted,
        this.blacklistCustomer.email
      );
      this.blacklistCustomer = null;
    },
    dismissBlacklistUpdate() {
      this.isShowBlacklistDialog = false;
      this.blacklistCustomer = null;
    },
    showBlacklistDialog(customer) {
      this.isShowBlacklistDialog = true;
      this.blacklistCustomer = customer;
    },
    getMinBookingDate() {
      return addMinutes(new Date(), 10);
    },
  },
  components: {
    OrganizationTopBar: OrganizationTopBar,
  },
};
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';

.chip {
  color: white;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
}
.pending {
  background-color: #f29339;
}
.accepted {
  background-color: #4bb543;
}

.cancelled {
  background-color: red;
}

.rejected {
  background-color: darkred;
}

.completed {
  background-color: #4bb543;
}

.charge-booking {
  background-color: indianred;
  border-color: indianred;
}

.charge-booking:enabled:hover {
  background-color: hsl(0, 53%, 48%);
  border-color: hsl(0, 53%, 48%);
}

.error-text {
  color: red;
}
</style>
